"use client";
import Script from "next/script";
import { QueryClient, QueryClientProvider } from "react-query";

// Font files can be colocated inside of `app`

import "./globals.css";
import { HeyWowFont, NunitoFont } from "./fonts/fonts-config";
export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const queryClient = new QueryClient();
  return (
    <html lang="en" className={NunitoFont.className}>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest"></link>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>
        Plan, save for and secure your child’s education conveniently.
      </title>
      <body>
        <QueryClientProvider client={queryClient}>
          {children}
        </QueryClientProvider>
        {/* Insert the Clarity script */}
        <Script id="clarity-analytics" strategy="afterInteractive">
          {`
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "mycuzvbwj9");
          `}
        </Script>
      </body>
    </html>
  );
}
